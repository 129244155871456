<template>
  <div class="home-container">
    <div class="video-container">
        <span id="home" style="position:absolute;top:-72px;"></span>
        <video src="../../../public/videos/home_play.mp4" autoplay loop muted playsinline></video>
        <div class="slot">
          <div class="main-box">
            <section class="title">
              <section class="wow fadeInUp">驾驶员位置信息插件</section>
              <section class="wow fadeInUp">硬件解决方案<span style="font-size:64px;">内测</span></section>
            </section>
            <section class="title-2 wow fadeInUp">
              好运宝H1·货运智能终端
            </section>
            <section class="title-3 wow fadeInUp">
              北斗 + GPS + AI人工智能，让运输安全可控
            </section>
            <button @click="toReservation" class="wow fadeInUp">
              申请内测资格（已获得网货牌照的企业）
              <i class="el-icon-right"></i>
            </button>
          </div>
        </div>
    </div>
    <div id="product" class="product_container flex-center">
      <div class="main-box">
        <div class="top">
          <div class="left wow fadeInUp">
            <h1>好运宝H1</h1>
            <p>
              好运宝集成硬件SDK与北斗系统，实时追踪、监测货物从发出到签收的状态、节点数据，全程轨迹验真，帮助企业高效管理每一笔运单。
            </p>
          <button @click="toReservation">
            预约免费试用
            <i class="el-icon-right"></i>
            </button>
          </div>
          <div class="right wow fadeInUp">
            <img src="../../assets/imgs/hyb1.gif">
          </div>
        </div>
        <div class="bottom wow fadeInUp">
          <div class="item">
            <img class="icon" src="../../assets/imgs/icons/实时在线@2x.png">
            <section class="title">实时在线</section>
            <section class="des">双重保障，集成硬件SDK与北斗系统，数据可靠留存稳定</section>
          </div>
          <div class="item">
            <img class="icon" src="../../assets/imgs/icons/轨迹验真@2x.png">
            <section class="title">轨迹验真</section>
            <section class="des">内嵌SDK，全自动追踪物流信息，数据实时上报交通部监管平台</section>
          </div>
          <div class="item">
            <img class="icon" src="../../assets/imgs/icons/智能便捷@2x.png">
            <section class="title">智能便捷</section>
            <section class="des">随时随地、即插即用，一键扫码登录小程序使用</section>
          </div>
          <div class="item">
            <img class="icon" src="../../assets/imgs/icons/全国通用@2x.png">
            <section class="title">全国通用</section>
            <section class="des">严格按照行业标准，网络货运行业通用</section>
          </div>
        </div>
      </div>
    </div>
    <div class="trajectort-container flex-center">
      <div class="main-box">
        <section class="title wow fadeInUp">
          智能追踪轨迹，助力行业进步， 成就智慧物流
        </section>
        <section class="des wow fadeInUp">
          实时追踪解决方案通过智能硬件设备好运宝对货物运输状态全程精准定位、实时上报。创新研发的硬件SDK，解决货运业务轨迹数据保活性不高的难题；实现了多接口信息上传，帮助企业全盘掌握运单运输轨迹、管理运力，助力企业高效管理；自带蓝牙通讯，一次绑定即可终身使用。
        </section>
        <section class="map wow fadeInUp">
          <img class="bg" src="../../assets/imgs/智能追踪2x.png">
          <img class="trajectory" src="../../assets/imgs/trajectory.gif">
          <section class="label">
          从货物发出到签收，自动追踪、监测、实时上传每一笔运单信息
          </section>
        </section>
        <section class="bottom">
          <div v-for="(item, index) in advantageList" :key="index" class="item wow fadeInUp"
          @mouseover="$nextTick(()=> {item.hover = true})"
          @mouseleave="$nextTick(()=> {item.hover = false})"
          >
            <transition :name="item.hover === true?'slide-up':'slide-down'" mode="out-in">
               <div class="back" v-if="item.hover === true" :key="`${index}-back`">
                <span class="label">{{item.label}}</span>
                <span class="desc">{{item.desc}}</span>
              </div>
              <div class="positive" v-else :key="`${index}positive`">
                <img class="icon" :src="item.icon">
                <span class="label">{{item.label}}</span>
              </div>

            </transition>

          </div>
        </section>
      </div>
    </div>
    <div id="solution" class="solution-container flex-center">
      <div class="main-box">
        <div class="section-1">
          <div class="title wow fadeInUp">
            智能追踪与监测解决方案
          </div>
          <div class="img wow fadeInUp">
            <img src="../../assets/imgs/智能追踪与监测解决方案2x.png">
          </div>
        </div>
        <div class="section-2">
          <div class="title wow fadeInUp">
            <span>目前行业存在的问题</span>
            <hr>
            </div>
          <div class="content">
            <div class="left wow fadeInUp">
              <img class="icon" src="../../assets/imgs/icons/独立APP@2x.png">
              <section class="desc">每家网络货运企业都需要开发独立APP，并适配交通部软件SDK</section>
            </div>
            <div class="right wow fadeInUp">
              <img class="icon" src="../../assets/imgs/icons/反感app@2x.png">
              <section class="desc">
                司机对于安装APP比较反感， 安装阻力大
              </section>
            </div>
          </div>
          <div class="wow fadeInUp">
            <p>
              网络货运企业需要具备独立开发APP的能力，且业务APP需适配交通部软件SDK，而大部分企业并不具备独立开发的能力，APP质量缺乏保障，导致市场上相当一部分APP会出现闪退、死机等多种问题。
            </p>
            <p>
              另一方面，司机在承接业务时，需要下载安装不同网络货运企业的APP，数量多到满屏、占满内存，导致执行门槛过高。
            </p>
          </div>

        </div>
        <div class="section-3">
          <div class="left wow fadeInUp">
            <div class="title">
              <span>好运宝 · H1 解决方案</span>
              <hr>
            </div>
            <div class="item">
              <img class="icon" src="../../assets/imgs/icons/嵌入SDK@2x.png">
              <div class="desc">
                <span class="bold">通过硬件嵌入方式，集成定位插件SDK和北斗系统。</span>
                硬件SDK解决与监管平台交互工作；北斗系统实时追踪，精准定位。网络货运行业皆可通用，解决无独立开发能力企业的困境。
              </div>
            </div>
            <div class="item">
              <img class="icon" src="../../assets/imgs/icons/小程序@2x.png">
              <div class="desc">
                <span class="bold">小程序一站式解决方案，随时随地、一扫即用。</span>
                司机无需下载安装APP管理操作，解放手机。
              </div>
            </div>
          </div>
          <div class="right wow fadeInUp">
            <img class="img" src="../../assets/imgs/hyb2.gif">
          </div>
        </div>
      </div>
    </div>
    <div id="form" class="form-container flex-center">
      <div class="main-box">
        <div class="">
          <span class="title">预约免费试用</span>
        </div>
        <el-form :model="formData" class="form" :rules="formRules" ref="form">
          <el-form-item prop="name">
            <el-input v-model="formData.name" placeholder="请输入">
              <span slot="prefix" class="">您的姓名</span>
              <span slot="suffix">必填</span>
            </el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input v-model="formData.phone" placeholder="请输入">
              <span slot="prefix">联系方式</span>
              <span slot="suffix">必填</span>
            </el-input>
          </el-form-item>
          <el-form-item prop="company" placeholder="请输入">
            <el-input v-model="formData.company">
              <span slot="prefix">公司名称</span>
            </el-input>
          </el-form-item>
          <section class="sub-btn">
            <button @click.prevent="submitHandle">提交预约</button>
          </section>
        </el-form>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import WOW from 'wow.js'
import { scrollToView } from '@/utils/scroll.js'
import { Message } from 'element-ui'
import { appointment } from '../../api.ts'
export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data () {
    return {
      hasMounted: false,
      resolveFn: null,
      advantageList: [{
        label: '多重追踪技术',
        icon: '多重追踪技术@2x.png',
        desc: '内置硬件BDS+LBS+WIFI，实时追踪、精准定位'
      }, {
        label: '货物异常监测',
        icon: '货物异常监测@2x.png',
        desc: '行驶轨迹、货运速度、货物撞击、时效异常、超时停留、位置偏离、开箱、掉包等实时监测'
      }, {
        label: '使用简单便捷',
        icon: '使用简单@2x.png',
        desc: '车载充电、巴枪扫描效率高、装车即可用、安装免拆卸'
      }, {
        label: '使用门槛低',
        icon: '使用门槛低@2x.png',
        desc: '通过小程序操作管理'
      }, {
        label: '人车对应绑定',
        icon: '人车对应绑定@2x.png',
        desc: '设备联通人、车，一人一车一设备'
      }, {
        label: '系统安全防盗',
        icon: '安全防盗@2x.png',
        desc: 'SDK与硬件集成，高安全高兼容防破解'
      }, {
        label: '状态、时效、路径可控',
        icon: '状态可控@2x.png',
        desc: '按业务要求设定电子围栏，货物发出、途经城市自动上报，货物到达目的地自动签收'
      }, {
        label: '可靠的供应链金融工具',
        icon: '可靠的供应链金融工具@2x.png',
        desc: '可根据合同条款，灵活设置货物发出送达时自动签收，发起货款收取请求，完善供应链金融管理链条'
      }].map(item => {
        return Object.assign({}, item, {
          icon: require(`../../assets/imgs/icons/${item.icon}`),
          hover: false
        })
      }),
      formData: {
        name: '',
        phone: '',
        company: ''
      },
      formRules: {
        name: [{ required: true, message: '您的姓名必填', tirgger: 'change' }],
        phone: [
          { required: true, message: '联系方式必填', tirgger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
                callback()
              } else {
                callback(new Error('手机号格式错误'))
              }
            },
            tirgger: 'change'
          }
        ],
        company: []
      }
    }
  },
  computed: {
    fullPath () {
      return `${this.$route.path || '/'}${this.$route.hash || ''}`
    }
  },
  methods: {
    init () {
      const wow = new WOW(
        {
          boxClass: 'wow', // default
          animateClass: 'animated', // default
          offset: 0, // default
          mobile: true, // default
          live: true // default
        }
      )
      wow.init()
    },
    async waitMounted () {
      if (this.hasMounted) {
        return this.hasMounted
      } else {
        return new Promise((resolve) => {
          this.resolveFn = resolve
        })
      }
    },
    toReservation () {
      scrollToView('#form')
    },

    async scrollToView () {
      await this.waitMounted()
      scrollToView(this.$route.hash)
    },
    async submitHandle () {
      try {
        const valid = await this.$refs.form.validate()
        if (valid) {
          appointment(this.formData).then(res=>{
            Message.success({
            type: 'success',
            message: '预约成功，我们将尽快联系您，请保持电话畅通',
            duration: 1500
          })
          })
        // do fetch api

        }
      } catch (err) {
        Message({
          type: 'error',
          message: err.message || '提交失败',
          duration: 1500
        })
      }
    }
  },
  watch: {
    fullPath: {
      handler (old, now) {
        this.scrollToView()
      },
      immediate: true
    }
  },
  mounted () {
    this.init()
    this.hasMounted = true
    this.resolveFn(this.hasMounted)
  }
}
</script>
<style lang="scss" scoped>
.home-container {
  button {
    cursor: pointer;
  }
  .flex-center {
    display: flex;
    justify-content: center;
  }
  .main-box {
    width: 1366px;
  }
  .video-container {
    position: relative;
    height: calc(100vh - 72px);
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .slot {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background-color: rgba($color: #000000, $alpha: 0.2);
      .main-box {
        padding: 0 94px;
        width: 1366px;
        color: #fff;
        text-align: left;
        .title {
          font-size: 48px;
          line-height: 1.5;
          font-weight: 600;

        }
        .title-2 {
          margin-top: 40px;
          font-size: 32px;
          line-height: 1.5;
          font-weight: 500
        }
        .title-3 {
          margin-top: 16px;
          font-size: 20px;
          line-height: 1.5;
          font-weight: 400px;
        }
        button {
          margin-top: 40px;
          width: 383px;
          height: 56px;
          border: 2px solid #fff;
          background: transparent;
          font-size: 18px;
          font-weight: 500px;
          color: #fff;
        }
      }
    }
  }
  .product_container {
    padding: 160px;
    .main-box {
      .top {
        display: flex;
        justify-content: space-between;
        .left {
          padding-left: 94px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          h1 {
            font-size: 60px;
            line-height: 60px;
            color: #363636;
            font-weight: 600;
            margin: 0;
            padding: 0;
          }
          p {
            width: 565px;
            margin: 0;
            padding: 0;
            margin-top: 40px;
            text-align: left;
            font-size: 18px;
            line-height: 2;
            color: #808080;
          }
          button {
            width: 200px;
            height: 56px;
            margin-top: 80px;
            background: #232323;
            color: #fff;
            font-size: 20px;
            border: none;
          }
        }
        .right {
          img {
            width: 675px;
          }
        }
      }
      .bottom {
        margin-top: 120px;
        padding: 0 94px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // width: 274px;
          .icon {
            width: 56px;
            height: 56px;
          }
          .title {
            margin-top: 32px;
            font-size: 28px;
            color: #363636;
            font-weight: bold;
          }
          .des {
            margin-top: 16px;
            width: 250px;
            font-size: 18px;
            line-height: 32px;
            color: #808080;
            text-align: left;
          }
        }
      }
    }
  }
  .trajectort-container {
    background: #f7f9fa;
    padding: 160px 0;
    .main-box {
      .title {
        width: 672px;
        font-size: 48px;
        font-weight: 600;
        margin: auto;
        color: #363636;
      }
      .des {
        padding: 0 32px;
        margin-top: 40px;
        font-size: 18px;
        line-height: 2;
        color: #808080;
      }
      .map {
        position: relative;
        margin-top: 40px;
        width: 100%;
        height: 640px;
        img.bg {
          position: absolute;
          top: 0;
          width: 100%;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
        }
        img.trajectory {
          position: absolute;
          top: 292px;
          right: 38px;
          width: 699px;
          z-index: 2;
        }
        .label {
          position: absolute;
          top: 83px;
          right: 180px;
          width: 430px;
          text-align: center;
          font-size: 24px;
          line-height: 1.5;
          font-weight: 500;
          color: #363636;
          z-index: 3;
        }

      }
      .bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          width: 324px;
          height: 216px;
          margin-top: 24px;
          cursor: pointer;
          &:hover {
            box-shadow: 2px 2px 20px rgb(221, 218, 218);
          }
          background: #fff;
          overflow: hidden;
          .positive {
            width: 100%;
            height: 100%;;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .label {
              font-size: 18px;
              // line-height: 36px;
              color: #363636;
              font-weight: 500;
              margin-top: 24px;
            }
          }
          .back {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .label {
              font-size: 20px;
              line-height: 36px;
              color: #363636;
              font-weight: 500;
            }
            .desc {
              margin-top: 16px;
            }
          }
          .icon {
            width: 56px;
            height: 56px;
          }

          .desc {
            width: 280px;
            font-size: 18px;
            line-height: 30px;
            color: #808080;
            font-weight: 400;
          }
        }
      }
    }
  }
  .solution-container {
    padding: 160px 0;
    padding-bottom: 100px;
    .main-box {
      .section-1 {
        .title {
          font-size: 48px;
          color: #363636;
          height: 67px;
          font-weight: 600;
        }
        .img {
          margin-top: 64px;
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
      .section-2 {
        margin-top: 60px;
        text-align: left;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363636;
          line-height: 48px;
          display: inline-block;
          margin-left: 56px;
           hr {
              margin: 0;
              height: 16px;
              border: none;
              background: #1677ff;
              opacity: 0.25;
              margin-top: -16px;
              margin-left: -16px;
              margin-right: -16px;
            }
        }
        .content {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 240px;
            flex: 1;
            padding: 68px 56px;
            background: #f7f9fa;
            .icon {
              width: 60px;
            }
            .desc {
              margin-left: 52px;
              font-size: 24px;
              line-height: 40px;
              color: #363636;
              font-weight: 600;
            }
          }
          .right {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 20px;
            height: 240px;
            flex: 1;
            padding: 68px 56px;
            background: #f7f9fa;
            .icon {
              width: 60px;
            }
            .desc {
              margin-left: 40px;
              font-size: 24px;
              line-height: 40px;
              color: #363636;
              font-weight: 600;
            }
          }
        }
        p {
          margin-top: 24px;
          font-size: 18px;
          line-height: 2;
          color: #363636;
          padding: 0 56px;
        }
      }
      .section-3 {
        margin-top: 120px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 56px;
          .title {
            font-size: 32px;
            line-height: 48px;
            color: #363636;
            font-weight: bold;
            hr {
              margin: 0;
              height: 16px;
              border: none;
              background: #1677ff;
              opacity: 0.25;
               margin-top: -16px;
              margin-left: -16px;
              margin-right: -16px;
            }
          }
          .item {
            margin-top: 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon {
              width: 50px;
            }
            .desc {
              margin-left: 38px;
              width: 507px;
              font-size: 18px;
              line-height: 2;
              color: #363636;
              .bold {
                font-weight:  bold;
              }
            }
          }
        }
        .right {
          .img {
            width: 706px;
          }
        }
      }
    }
  }
  .form-container {
    padding: 125px 0 103px 0;
    background: #F7F9FA;
    .main-box {
      width: 560px;
      .title {
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
        color: #363636;
      }
      .form {
        margin-top: 48px;
        /deep/ {
          .el-input__prefix {
           left: 24px;
           font-size: 16px;
           color: #363636;
          }
          .el-input__suffix {
            right: 24px;
            font-size: 16px;
            color: #363636;
          }
          .el-input__inner {
            text-indent: 82px;
            height: 56px;
            line-height: 1;
            &::placeholder {
             font-size: 16px;
             color: #999;
            }
          }
          .el-form-item {
            margin-bottom: 24px;
          }
          .el-form-item__content {
            line-height: 64px;
          }
        }
        .sub-btn {
          width: 100%;
          button {
            width: 100%;
            height: 56px;
            border-radius: 4px;
            font-size: 20px;
            color: #fff;
            background: #363636;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
<style>
.flip-enter-active {
  transition: all .3s ease-out;
}
.flip-leave-active {
    transition: all .3s ease-out;
}
.flip-leave-to {
  transform: rotateY(90deg);
}
.flip-enter {
  transform: rotateY(-90deg)
}
.slide-up-enter-active,.slide-up-leave-active {
  transition: all .15s ease-out;
}
.slide-up-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-up-enter {
  transform: translateY(100%);
  opacity: 0;
}
.slide-down-enter-active,.slide-down-leave-active {
  transition: all .15s ease-out;
}
.slide-down-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.slide-down-enter {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
